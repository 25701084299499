<template>
   <v-container bg fill-height grid-list-md text-center class="mt-16">
        <v-layout wrap align-center>
          <v-flex justify-center class="mt-16">
            <div>
                <v-icon color="red" size="6rem">error</v-icon>
                <h1 class="text-h3 font-weight-medium pt-3 pb-5">{{ message.msg }}</h1>
                <span class="text-blue-gray-lighten-3 pt-8"> {{description.msg}}</span>
            </div>
          </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
  name: 'expirations',
  data () {
    return{
      message:{ msg: this.$t('errorMessage.page_unauthorized'), value: 'Error: (401) unauthorized' },
      description:{ msg: this.$t('errorMessage.page_unauthorized_description'), value: 'The security token has expired, the action has been cancelled.' },
    }
  },
}
</script>